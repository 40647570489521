import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import type {SectionMeta} from '@/components/shared/types'
import useWindowDimensions, {WindowSizeKey} from './useWindowWidthListener'

export type BackgroundType = 'image' | 'video'

type BackgroundCSSProps = {
  color: string
  size: string
  horizontalOffset: string
  verticalOffset: string
  backgroundPosition?: string
  horizontalPosition?: string
  verticalPosition?: string
  attachment: string
  opacity: number
  backgroundImage?: string
  objectFit?: string
  objectPosition?: string
  backgroundRepeat?: string
}

export interface SectionBackgroundProps {
  meta: SectionMeta & {
    style?: {
      config?: {
        general?: {
          section?: {background?: BackgroundCSSProps}
        }
      }
    }
  }
  className: string
  backgroundType: BackgroundType
  pageSectionBackground: string
  mobileBackgroundImage: string
  mobileTransitionPoint: keyof typeof WindowSizeKey
  cms: boolean
}

const StyledImageBackground = styled.div<BackgroundCSSProps>`
  background-color: ${(props) => props.color};
  background-size: ${(props) => props.size};
  background-position: ${(props) => props.backgroundPosition};
  background-attachment: ${(props) => props.attachment};
  background-repeat: ${(props) => props.backgroundRepeat};
  opacity: ${(props) => props.opacity};
  background-image: ${(props) => `url(${props.backgroundImage})`};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledVideoBackground = styled.video<BackgroundCSSProps>`
  object-fit: ${(props) => props.objectFit};
  object-position: ${(props) => props.objectPosition};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const DEFAULT_BACKGROUND_CSS_PROPS = {
  color: 'none',
  size: 'cover',
  horizontalOffset: '0px',
  verticalOffset: '0px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  attachment: 'scroll',
  opacity: 1,
} as const

const computeBackgroundPosition = (
  horizontalPosition: 'left' | 'center' | 'right',
  verticalPosition: 'top' | 'center' | 'bottom',
  horizontalOffset = '0px',
  verticalOffset = '0px'
): string => {
  const getOffsetValue = (position: string, offset: string) => {
    if (position === 'center') return 'center'
    if (position === 'right' || position === 'bottom') {
      if (offset === '0px' || offset === 'px') return '100%'
      return `calc(100% - ${offset})`
    }
    if (offset === '0px' || offset === 'px') return '0%'
    return `${offset}`
  }

  const horizontalValue = getOffsetValue(horizontalPosition, horizontalOffset)
  const verticalValue = getOffsetValue(verticalPosition, verticalOffset)

  return `${horizontalValue} ${verticalValue}`
}

const SectionBackground: React.FC<SectionBackgroundProps> = ({
  meta,
  className,
  backgroundType,
  pageSectionBackground,
  mobileBackgroundImage,
  mobileTransitionPoint
}) => {
  const {size: windowSize} = useWindowDimensions()
  const [backgroundCSS, setBackgroundCSS] = useState<BackgroundCSSProps>(DEFAULT_BACKGROUND_CSS_PROPS)
  useEffect(() => {
    if (!pageSectionBackground) return

    const {
      color = DEFAULT_BACKGROUND_CSS_PROPS.color,
      size = DEFAULT_BACKGROUND_CSS_PROPS.size,
      horizontalPosition = DEFAULT_BACKGROUND_CSS_PROPS.backgroundPosition,
      verticalPosition = DEFAULT_BACKGROUND_CSS_PROPS.backgroundPosition,
      horizontalOffset = DEFAULT_BACKGROUND_CSS_PROPS.horizontalOffset,
      verticalOffset = DEFAULT_BACKGROUND_CSS_PROPS.verticalOffset,
      attachment = DEFAULT_BACKGROUND_CSS_PROPS.attachment,
      opacity = DEFAULT_BACKGROUND_CSS_PROPS.opacity,
    } = meta?.style?.config?.general?.section?.background  || {}

    const updatedCSS: BackgroundCSSProps = {
      ...DEFAULT_BACKGROUND_CSS_PROPS,
      color,
      size,
      attachment,
      opacity,
    }

    const backgroundPosition = computeBackgroundPosition(
      horizontalPosition as 'left' | 'center' | 'right',
      verticalPosition as 'top' | 'center' | 'bottom',
      horizontalOffset,
      verticalOffset
    )

    if (backgroundType === 'image') {
      updatedCSS.backgroundPosition = backgroundPosition
      const isMobile = windowSize <= WindowSizeKey[mobileTransitionPoint]
      updatedCSS.backgroundImage = isMobile && mobileBackgroundImage ? mobileBackgroundImage : pageSectionBackground
    }

    if (backgroundType === 'video') {
      updatedCSS.objectFit = size
      updatedCSS.objectPosition = backgroundPosition
    }

    setBackgroundCSS(updatedCSS)
  }, [meta, backgroundType, pageSectionBackground, windowSize])

  return (
    <>
      {pageSectionBackground ? (
        <>
          {backgroundType === 'image' && (
            <StyledImageBackground className={className} {...backgroundCSS} />
          )}
          {backgroundType === 'video' && (
            <StyledVideoBackground className={className} {...backgroundCSS} loop autoPlay muted playsInline>
              <source src={pageSectionBackground} type="video/mp4" />
            </StyledVideoBackground>
          )}
        </>
      ) : (
        <div
          className={`${className} section-background`}
          style={backgroundColorInlineStyle(meta?.style?.config?.general?.section?.background?.color)}>
        </div>
      )}
    </>
  )
}

function backgroundColorInlineStyle(color: string | undefined) {
  if (color) {
    return {backgroundImage: 'unset'}
  }
}

export default SectionBackground
